import React, { useEffect, useState } from 'react'

import Image from '@/components/base/image'

import BracketLeft from './bracket-left/BracketLeft'
import BracketRight from './bracket-right'

import { BracketProps } from './types'
import { functions } from '@wap-client/core'

const Bracket: React.FunctionComponent<BracketProps> = ({
  colors = [],
  duration,
  image,
  align,
}) => {
  const [colorArray, setColorArray] = useState(colors)

  useEffect(() => {
    const interval = setInterval(() => {
      setColorArray((prevColors) => {
        const newColors = [...prevColors]
        for (let i = newColors.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[newColors[i], newColors[j]] = [newColors[j], newColors[i]]
        }
        return newColors
      })
    }, duration)

    return () => clearInterval(interval)
  }, [])

  return (
    <div
      className={functions.classnames(
        'animation-spreat',
        image?.src ? '' : 'mb-clear'
      )}
      style={{
        alignItems:
          align === 'start'
            ? 'flex-start'
            : align === 'center'
            ? 'center'
            : align === 'end'
            ? 'flex-end'
            : '',
      }}
    >
      <BracketLeft colors={colorArray} />
      {image && (
        <figure>
          <Image {...image} alt="Bracket Image" />
        </figure>
      )}
      <BracketRight colors={colorArray} />
    </div>
  )
}

export default Bracket
